<ng-container *ngIf="showSkeleton; else calendar">
  <div class="eb-skeleton mb-2 h-3rem"></div>
  <div class="eb-skeleton" [ngClass]="!isOverlay ? ' eb-skeleton--calendar w-full' : 'eb-skeleton--calendar__overlay'"></div>
</ng-container>

<ng-template #calendar>
  <eb-calendar
    [selectedDate]="selectedDate"
    [data]="calendarData"
    [calendarItemTmpl]="calendarItemTmpl"
    [calendarItemActionTmpl]="calendarItemActionTmpl"
    [equalDays]="equalDays"
    (calendarViewChanged)="checkAvailability($event)"
    (dateChanged)="calendarViewChange($event)"
    (clickDate)="showInDrawer($event)"
    (selectedCalendarItemChanged)="onSelectedCalendarItemChanged($event)"
    [headerFormat]="calendarFilterHeaderFormat"
    [checkHeaderFormat]="checkHeaderFormat"
    [dateMode]="dateMode"
    [dateFrom]="dateFrom"
  >
    <ng-template #calendarItemActionTmpl let-data let-date="date" let-calendarViewItemElRef="calendarViewItemElRef">
      <span *ngIf="data?.length > 0" class="eb-lg-visible">
        <svg-icon
          [id]="'overlay-dots-' + data[0].date"
          key="dots"
          [ebOverlay]="overlayPanel"
          [ebOverlayData]="data"
          [ebOverlayOrigin]="calendarViewItemElRef"
        />
      </span>
    </ng-template>
    <ng-template #calendarItemTmpl let-data let-date="date">
      <div class="eb-title-event-calendar__item eb-lg-visible" *ngIf="data">
        <ng-container *ngIf="data.length === 1; then singleItemsTmpl; else manyItemsTmpl" />
        <ng-template #singleItemsTmpl>
          <div class="mb-2">
            <h6 class="eb-title-event-calendar__item__title">{{ data[0].city }}</h6>
            <div class="eb-title-event-calendar__item__description">{{ data[0].placeName }}</div>
          </div>
          <ng-template
            [ngTemplateOutlet]="titleEventAvailabilityTmpl"
            [ngTemplateOutletContext]="{ item: data[0], freeSeatsCount: titleEventAvailabilityMap.get(data[0].id)?.freeSeatsCount }"
          />
        </ng-template>
        <ng-template #manyItemsTmpl>
          <div class="mb-2 center-center">
            <h6 class="eb-title-event-calendar__item__title">
              {{ data.length < 4 ? data.length : '4+ ' }} {{ 'shared.title-event-calendar.dates' | transloco }}
            </h6>
          </div>
          <button eb-button ebType="primary" ebSize="small" (click)="onOpenOverlay(data)">
            {{ 'shared.title-event-calendar.show' | transloco }}
          </button>
        </ng-template>
      </div>
      <div class="flex align-items-end justify-content-center" *ngIf="data?.length">
        <span
          class="eb-tag eb-tag--primary eb-size--small fw-bold justify-content-center"
          [ngClass]="{ 'eb-lg-hidden': !hideNumberOfEventsPerDay, flex: hideNumberOfEventsPerDay }"
          (click)="showInDrawer(data)"
          >{{ data?.length }}</span
        >
      </div>
    </ng-template>
  </eb-calendar>
</ng-template>

<eb-overlay-panel #overlayPanel [overlayStyle]="'eb-title-event-calendar-item-overlay-panel'">
  <ng-template let-data>
    <ng-container [ngTemplateOutlet]="calendarItemDetailsTmpl" [ngTemplateOutletContext]="{ $implicit: data }" />
  </ng-template>
</eb-overlay-panel>

<ng-template #calendarItemDetailsTmpl let-data>
  <ng-container
    [ngTemplateOutlet]="data.length === 1 ? singleOverlayPanelTmpl : multiOverlayPanelTmpl"
    [ngTemplateOutletContext]="{ $implicit: data, first: data[0] }"
  />
</ng-template>
<ng-template #singleOverlayPanelTmpl let-item="first">
  <div class="eb-title-event-calendar-item-overlay">
    <svg-icon key="close" class="close-overlay-icon eb-lg-visible" (click)="onCloseOverlay()" />
    <div class="eb-title-event-calendar-item-overlay__header flex">
      <h6 class="mr-2">{{ item.date | date: 'EEEE' : undefined : 'PL' | titlecase }}</h6>
      <div>{{ item.date | date: 'dd.MM.yyyy, HH:mm' : undefined : 'PL' }}</div>
    </div>
    <div class="eb-title-event-calendar-item-overlay__content">
      <div class="eb-title-event-calendar-item-overlay__title">
        <h5>{{ item.city }}</h5>
      </div>
      <div class="eb-title-event-calendar-item-overlay__description">{{ item.placeName }}</div>
    </div>
    <div class="eb-divider"></div>
    <ng-template
      [ngTemplateOutlet]="titleEventAvailabilityTmpl"
      [ngTemplateOutletContext]="{
        item,
        isOverlay: true,
        minPrice: titleEventAvailabilityMap.get(item.id)?.minPrice,
        freeSeatsCount: titleEventAvailabilityMap.get(item.id)?.freeSeatsCount
      }"
    />
  </div>
</ng-template>
<ng-template #multiOverlayPanelTmpl let-items>
  <eb-tabset class="calendar-tabset" [showLoader]="false">
    <eb-tab *ngFor="let item of items" [header]="item.date | date: 'HH:mm'">
      <ng-template>
        <ng-container [ngTemplateOutlet]="singleOverlayPanelTmpl" [ngTemplateOutletContext]="{ first: item }" />
      </ng-template>
    </eb-tab>
  </eb-tabset>
</ng-template>

<eb-drawer #drawer class="calendar-tabset" placement="bottom" [bodyStyle]="{ padding: '0 0 80px' }">
  <ng-container [ngTemplateOutlet]="calendarItemDetailsTmpl" [ngTemplateOutletContext]="{ $implicit: currentCalendarItem }" />
</eb-drawer>

<ng-template
  #titleEventAvailabilityTmpl
  let-item="item"
  let-isOverlay="isOverlay"
  let-minPrice="minPrice"
  let-freeSeatsCount="freeSeatsCount"
>
  <ng-container [ngSwitch]="titleEventAvailabilityMap.get(item.id)?.titleEventAvailability">
    <ng-container *ngSwitchCase="titleEventAvailabilities.AVAILABLE">
      <div *ngIf="isOverlay">
        <p *ngIf="freeSeatsCount" class="eb-size--tiny mb-0">
          @if (+freeSeatsCount > 10) {
            {{ 'shared.title-event-calendar.seatsAvailable' | transloco }}
            <span class="font-bold">{{ freeSeatsCount }} {{ 'shared.title-event-calendar.pieces' | transloco }}</span>
          } @else {
            <span class="font-bold">{{ 'shared.title-event-calendar.lastPieces' | transloco }}</span>
          }
        </p>
        <eb-buy-ticket-button
          class="width-100"
          ebSize="default"
          [titleEvent]="item"
          [virtualPageView]="virtualPageView"
          [partnerId]="partnerId"
          [tokenCheckSum]="tokenCheckSum"
        >
          <svg-icon key="ticket" />
          {{ 'shared.title-event-calendar.buyTickets' | transloco }}

          <span *ngIf="minPrice | ebIsNumber" class="eb-title-event-calendar__price"
            >{{ 'shared.title-event-calendar.from' | transloco }} {{ minPrice | ebCurrency: 'PLN' }}</span
          >
        </eb-buy-ticket-button>
        <p *ngIf="minPrice | ebIsNumber" class="eb-title-event-calendar__price-info mt-1">
          {{ 'libs.title-event-bar.allFeesIncluded' | transloco }}
        </p>
      </div>
      <div *ngIf="!isOverlay">
        <p *ngIf="freeSeatsCount" class="eb-free-seats-count--calendar eb-size--tiny font-bold mb-0">
          @if (+freeSeatsCount > 10) {
            {{ freeSeatsCount }} {{ 'shared.title-event-calendar.pieces' | transloco }}
          } @else {
            {{ 'shared.title-event-calendar.lastPieces' | transloco }}
          }
        </p>
        <eb-buy-ticket-button
          [titleEvent]="item"
          [partnerId]="partnerId"
          [tokenCheckSum]="tokenCheckSum"
          [ebSize]="'small'"
          [virtualPageView]="virtualPageView"
        >
          {{ 'shared.title-event-calendar.buyTicket' | transloco }}
        </eb-buy-ticket-button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="undefined">
      <svg-icon key="loading" />
    </ng-container>
    <ng-container *ngSwitchCase="titleEventAvailabilities.CHECK_FREE_SEATS">
      <svg-icon key="loading" />
    </ng-container>
    <ng-container *ngSwitchCase="titleEventAvailabilities.UNDEFINED">
      <svg-icon key="loading" />
    </ng-container>
    <ng-container *ngSwitchDefault>
      <eb-fan-alert
        [iconVisible]="false"
        [fanAlertParams]="{ objectId: item.titleId, name: item.title, partnerId, fanAlertType: fanAlertTypes.TITLE }"
        observedButtonColor="purple"
        observedButtonType="primary"
        [labelVisible]="true"
        size="small"
      />
    </ng-container>
  </ng-container>
</ng-template>
