import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    inject,
    PLATFORM_ID,
} from '@angular/core';
import { ListViewTypes } from '@e-bilet/ui-list-view-toggle-button';
import { TranslocoService } from '@ngneat/transloco';
import { BaseVirtualPageView } from '../../../../../../../libs/gtm/src/lib/virtual-page-view';
import { PageParamsManager } from '../../../infrastructure/page-params-manager.class';
import { TitleEvent, TitleEventGroup } from '../../../rest-api/models/title-page.model';
import { ICalendarItem } from '../calendar/calendar-item.interface';
import { CalendarHelper } from '../calendar/calendar.helper';
import { CalendarMapper } from '../calendar/calendar.mapper';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'eb-title-event-list',
    templateUrl: './title-event-list.component.html',
    styleUrls: ['./title-event-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbTitleEventListComponent implements OnInit, OnChanges {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _translocoService = inject(TranslocoService);

    todayDateKey = '';
    tomorrowDateKey = '';
    calendarData: [string, TitleEvent[]][] = [];
    namedTitleEventsGroups: TitleEventGroup[] = [];
    unnamedTitleEventsGroups: TitleEventGroup[] = [];
    checkCalendarFilterHeaderFormat = false;

    filteredEvents: TitleEvent[] = [];
    hideNumberOfEventsPerDay = true;
    calendarFilterHeaderFormat: 'EEEE' | 'EEE' = 'EEE';

    readonly availableListViewTypes = [ListViewTypes.BAR, ListViewTypes.CALENDAR];

    readonly listViewTypes = ListViewTypes;
    selectedDate: Date | undefined;
    skeletonVisible = false;
    activeLanguage = 'pl';
    showDate = true;
    isDayViewLoading = false;

    @Input() listViewType = ListViewTypes.BAR;
    @Input() isLoading = false;
    @Input() headerVisible = true;
    @Input() barPagedList = false;
    @Input() buttonLeft = false;
    @Input() isThumbnailHidden = false;
    @Input() groupEvents = false;
    @Input() artistName: string | undefined;
    @Input() partnerId?: string;
    @Input() tokenCheckSum: string | undefined;

    @Input() titleEvents: TitleEvent[] = [];
    @Input() titleEventsGroups: TitleEventGroup[] = [];
    @Input() virtualPageView: BaseVirtualPageView | undefined;
    @Input() totalItems = 0;
    @Input() pageParamsManager: PageParamsManager | undefined;
    @Input() addMicroData = true;
    @Input() microDescription: string | undefined;
    @Input() artists: string[] = [];
    @Input() titleUrl: string | undefined;
    @Input() isTitlePage = false;
    @Input() showDelayedPayments: boolean | undefined;
    @Input() showFanAlert = true;
    @Input() isEventDateHidden: boolean | undefined = undefined;
    @Input() dateMode = false;
    @Input() dateFrom: string | undefined;
    @Input() calendarEvents: Map<string, TitleEvent[]> | undefined;
    @Output() readonly calendarViewChanged = new EventEmitter<ICalendarItem<TitleEvent>[]>();

    public ngOnInit(): void {
        this.activeLanguage = this._translocoService.getActiveLang();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.titleEvents?.currentValue) {
            this.filteredEvents = this.titleEvents;
            this.createCalendarData();
        }
        if (changes.titleEventsGroups?.currentValue) {
            this.namedTitleEventsGroups = this.titleEventsGroups.filter((group) => !!group.groupName);
            this.unnamedTitleEventsGroups = this.titleEventsGroups.filter((group) => !group.groupName);
            this.titleEvents = this.titleEventsGroups.reduce(
                (titleEvents, titleEventGroup) => titleEvents.concat(titleEventGroup.events),
                [] as TitleEvent[],
            );
            this.filteredEvents = this.titleEvents;
            this.createCalendarData();
        }
        if (this.isEventDateHidden === undefined && this.titleEvents.length) {
            this.isEventDateHidden = this.titleEvents.every(({ isEventDateHidden }) => isEventDateHidden === true);
        }
    }

    public changeFilteredEvents(item: ICalendarItem<TitleEvent>, overlayPanel?: any): void {
        this.isDayViewLoading = true;
        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => {
                this.selectedDate = item.date;
                this.filteredEvents = this.titleEvents.filter((titleEvent) => new Date(titleEvent.date) >= item.date);
                this.createCalendarData();
                if (overlayPanel) {
                    overlayPanel.close();
                }
            });
        } else {
            this.selectedDate = item.date;
            this.filteredEvents = this.titleEvents.filter((titleEvent) => new Date(titleEvent.date) >= item.date);
            this.createCalendarData();
            if (overlayPanel) {
                overlayPanel.close();
            }
        }
    }

    private createCalendarData(): void {
        const today = new Date();
        this.todayDateKey = CalendarHelper.createKey(today);
        today.setDate(today.getDate() + 1);
        this.tomorrowDateKey = CalendarHelper.createKey(today);
        this.calendarData = Array.from(
            CalendarMapper.map(this.filteredEvents, (titleEvent) => new Date(titleEvent.date)),
        );
    }

    protected onDayViewChanged(): void {
        this.isDayViewLoading = false;
    }
}
