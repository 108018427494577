import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { UrlHelper } from '../../../helpers/url.helper';
import { InternationalizationService } from '../../../services/internationalization.service';

@Component({
    selector: 'eb-bottom-nav-bar',
    templateUrl: './bottom-nav-bar.component.html',
    styleUrls: ['./bottom-nav-bar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class EbBottomNavBarComponent {
    isMegaMenuDrawerVisible = false;
    isSearchDrawerVisible = false;

    @HostBinding('class.eb-bottom-nav-bar') protected get isBottomNavBar(): boolean {
        return true;
    }
    
    constructor(private _internationalizationService: InternationalizationService) {}

    protected showLogin(): void {
        window.location.href = UrlHelper.getLoginUrl(this._internationalizationService.isEnglishActive);
    }
}
