import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewEncapsulation,
    inject,
    PLATFORM_ID,
} from '@angular/core';
import { IPageParam } from './page-param.interface';
import { isPlatformBrowser } from '@angular/common';
@Component({
    selector: 'eb-paged-list',
    templateUrl: './paged-list.component.html',
    styleUrls: ['./paged-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class EbPagedListComponent<T> implements OnInit, AfterViewChecked {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    private _totalVisible = 0;
    private _items: T[] = [];
    private _previousItems: T[] = [];
    private _previousTotalVisible = 0;

    protected get totalVisible(): number {
        return this._totalVisible;
    }

    protected get hasMoreItems(): boolean {
        return this.totalItems > this.totalVisible;
    }

    @Input({ required: true }) public set items(items: T[]) {
        this._items = items || [];

        this._totalVisible = this.localPaging ? this.pageSize : this._items.length;
    }

    public get items(): T[] {
        return this._items;
    }

    @Input() pageSize = 10;
    @Input() totalItems = 0;
    @Input() localPaging = false;
    @Input() infiniteScroll = false;
    @Input() loading = false;
    @Input() styleClass: any;
    @Input() showMoreLabel: string | undefined;
    @Input() buttonLeft = false;

    @Output() fetchingPage = new EventEmitter<IPageParam>();
    @Output() itemsChanged = new EventEmitter<void>();

    @ContentChild(TemplateRef) itemTemplate!: TemplateRef<{ $implicit: T; i: number }>;

    public ngOnInit(): void {
        this._totalVisible = this.localPaging ? this.pageSize : this._items.length;
        this._previousItems = this.items;
        this._previousTotalVisible = this._totalVisible;
    }

    public ngAfterViewChecked(): void {
        if (this._previousItems !== this.items) {
            this._previousItems = this.items;
            this.itemsChanged.emit();
        }
        if (this._previousTotalVisible !== this._totalVisible) {
            this._previousTotalVisible = this._totalVisible;

            if (isPlatformBrowser(this._platformId)) {
                setTimeout(() => {
                    this._changeDetectorRef.markForCheck();
                    this.loading = false;
                });
            } else {
                this._changeDetectorRef.markForCheck();
                this.loading = false;
            }
        }
    }

    protected showMore(): void {
        if (this.localPaging) {
            this.loading = true;
            if (isPlatformBrowser(this._platformId)) {
                setTimeout(() => {
                    this._changeDetectorRef.markForCheck();
                    this._totalVisible += this.pageSize;
                });
            } else {
                this._changeDetectorRef.markForCheck();
                this._totalVisible += this.pageSize;
            }
        } else {
            this.fetchingPage.next({
                size: this.pageSize,
                top: this._totalVisible,
            });
        }
    }
}
