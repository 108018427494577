<div class="eb-calendar-nav mb-3">
  <div>
    <button *ngIf="hasPrev" eb-button (click)="switchMonths(MonthsSwitchDirection.PREVIOUS)">
      <svg-icon key="chevron-left" />
    </button>
  </div>
  <div class="eb-calendar-nav__label">
    {{ activeMonth() | date: 'LLLL yyyy' : undefined : i18nService.activeLanguage }}
  </div>
  <div>
    <button *ngIf="hasNext" eb-button (click)="switchMonths(MonthsSwitchDirection.NEXT)">
      <svg-icon key="chevron-right" />
    </button>
  </div>
</div>
<div class="eb-calendar-view" [ngClass]="{ 'eb-calendar-view--equal': equalDays }">
  <div class="eb-calendar-view-header p-2" *ngFor="let header of headerDates">
    {{ header | date: headerFormat : undefined : i18nService.activeLanguage }}
  </div>
  <div
    class="eb-calendar-view-item"
    *ngFor="let item of activeCalendarView()"
    #calendarViewItemElRef
    [ngClass]="{ 'eb-calendar-view-item--with-data': hasData(item) }"
    (click)="onClickDate(item)"
  >
    <div class="eb-calendar-view-item__header flex">
      <span class="eb-calendar-view-item__day" [ngClass]="{ 'eb-calendar-view-item__day--today': isDateToday(item.date) }">{{
        item.label
      }}</span>
      <span class="eb-calendar-view-item__actions">
        <ng-template
          [ngTemplateOutlet]="calendarItemActionTmpl"
          [ngTemplateOutletContext]="{ date: item.date, $implicit: data.get(item.key), calendarViewItemElRef: calendarViewItemElRef }"
        />
      </span>
    </div>
    <div class="eb-calendar-view-item__content" [ngClass]="{ 'eb-calendar-view-item__content--flex': equalDays }">
      <ng-content />
      <!-- błąd hydracji https://github.com/angular/angular/issues/50543 -->
      <ng-template [ngTemplateOutlet]="calendarItemTmpl" [ngTemplateOutletContext]="{ date: item.date, $implicit: data.get(item.key) }" />
    </div>
  </div>
</div>
