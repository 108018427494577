<div class="eb-bottom-nav-bar__actions">
  <button eb-button [ebGhost]="true" [ngClass]="{'eb-bottom-nav-bar--active': isMegaMenuDrawerVisible}" (click)="isSearchDrawerVisible = false; isMegaMenuDrawerVisible = !isMegaMenuDrawerVisible">
    <svg-icon key="grid" />
    {{ 'shared.bottom-nav-bar.categories' | transloco }}
  </button>

  <button eb-button [ebGhost]="true" [ngClass]="{'eb-bottom-nav-bar--active': isSearchDrawerVisible}" (click)="isMegaMenuDrawerVisible = false; isSearchDrawerVisible = !isSearchDrawerVisible">
    <svg-icon key="search" />
    {{ 'shared.bottom-nav-bar.search' | transloco }}
  </button>

  <button eb-button [ebGhost]="true" (click)="showLogin()">
    <svg-icon key="user" />
    {{ 'shared.bottom-nav-bar.account' | transloco }}
  </button>
</div>

<eb-category-mega-menu-drawer [(isVisible)]="isMegaMenuDrawerVisible" />

<eb-search-drawer [(isVisible)]="isSearchDrawerVisible" />
