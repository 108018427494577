import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DestroyRef, Directive, ElementRef, HostListener, Input, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subscription, merge } from 'rxjs';
import { EbDropdownPanel } from './dropdown-panel.interface';

@Directive({
    selector: '[ebDropdownTriggerFor]',
    exportAs: 'ebDropdownTriggerForDirective',
})
export class EbDropdownTriggerForDirective {
    private readonly _overlay = inject(Overlay);
    private readonly _elementRef = inject(ElementRef);
    private readonly _viewContainerRef = inject(ViewContainerRef);

    isDropdownOpen = false;
    private _overlayRef!: OverlayRef;
    private _dropdownClosingActionsSub: Subscription = Subscription.EMPTY;
    private _destoryRef = inject(DestroyRef);

    @HostListener('click') public toggleDropdown(): void {
        this.isDropdownOpen ? this._destroyDropdown() : this._openDropdown();
    }

    @Input('ebDropdownTriggerFor') public dropdownPanel!: EbDropdownPanel;

    private _openDropdown(): void {
        this.isDropdownOpen = true;
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this._overlay.scrollStrategies.close(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._elementRef)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                        offsetY: 8,
                    },
                ]),
        });

        if (this.dropdownPanel.templateRef) {
            const templatePortal = new TemplatePortal(this.dropdownPanel.templateRef, this._viewContainerRef);
            this._overlayRef?.attach(templatePortal);
        }

        this._dropdownClosingActionsSub = this._dropdownClosingActions()
            .pipe(takeUntilDestroyed(this._destoryRef))
            .subscribe(() => this._destroyDropdown());
    }

    private _dropdownClosingActions(): Observable<any> {
        const backdropClick$ = this._overlayRef?.backdropClick();
        const detachment$ = this._overlayRef?.detachments();
        const dropdownClosed = this.dropdownPanel?.closed;

        return merge(backdropClick$, detachment$, dropdownClosed);
    }

    private _destroyDropdown(): void {
        if (!this._overlayRef || !this.isDropdownOpen) {
            return;
        }

        this._dropdownClosingActionsSub?.unsubscribe();
        this.isDropdownOpen = false;
        this._overlayRef.detach();
    }
}
