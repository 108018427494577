<button
  [attr.aria-label]="labels[brand]"
  eb-button
  [ebType]="ebType"
  [ebSize]="ebSize"
  [ebOnlyIcon]="true"
  [ngSwitch]="brand"
  (click)="openLink()"
>
  <svg-icon *ngSwitchCase="1" size="md" key="brand-facebook" />
  <svg-icon *ngSwitchCase="2" size="md" key="brand-twitter" />
  <svg-icon *ngSwitchCase="3" size="md" key="brand-instagram" />
  <svg-icon *ngSwitchCase="4" size="md" key="brand-tiktok" />
  <svg-icon *ngSwitchCase="6" size="md" key="brand-spotify" />
  <svg-icon *ngSwitchCase="7" size="md" key="brand-youtube" />
  <svg-icon *ngSwitchCase="8" size="md" key="brand-linkedin" />
  <svg-icon *ngSwitchDefault size="md" key="link" />
</button>
