import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable, Subject, from, lastValueFrom } from 'rxjs';
import { bufferTime, catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { IFreeSeats, IFreeSeatsRequest } from '../rest-api/models/free-seats.model';
import {
    ISingleTitleEventAvailabilityRequest,
    ITitleEventAvailabilityRequest,
    TitleEventAvailabilities,
    TitleEventAvailabilityData,
} from '../rest-api/models/title-event-availability.models';
import { EventDateStatus, TitleEvent } from '../rest-api/models/title-page.model';
import { TitleEventRestService } from '../rest-api/title-event-rest.service';
import { onlyDigitsExp } from '../validators/reg-exps/reg-exps.const';
import { BaseStoreService } from './base-store.service';

@Injectable({
    providedIn: 'root',
})
export class TitleEventAvailabilityStoreService extends BaseStoreService<
    ITitleEventAvailabilityRequest,
    Map<string, TitleEventAvailabilityData>
> {
    private readonly _titleEventRestService = inject(TitleEventRestService);
    private readonly _platformId = inject(PLATFORM_ID);

    private readonly _decryptedEventIdMap = new Map<string, string>();
    private readonly _map = new Map<string, BehaviorSubject<TitleEventAvailabilityData>>();

    private readonly _request$ = new Subject<ISingleTitleEventAvailabilityRequest>();
    readonly maxIdsInRequests = 10;
    readonly timeToFillRequest = 1000;

    constructor() {
        super();

        if (isPlatformBrowser(this._platformId)) {
            this._request$
                .pipe(
                    bufferTime(this.timeToFillRequest, null, this.maxIdsInRequests),
                    filter((singleRequests) => !!singleRequests.length),
                    map((singleRequests) => this._mapSingleToListTequest(singleRequests)),
                    mergeMap((request) => super.load(request)),
                    takeUntilDestroyed(),
                )
                .subscribe();
        }
    }

    public load(
        request: ITitleEventAvailabilityRequest,
        forceLoad = false,
    ): Observable<Map<string, TitleEventAvailabilityData>> {
        const newTitelEvents = request.events.filter(
            (titleEvent) =>
                !this._map.has(titleEvent.id) ||
                this._map.get(titleEvent.id)?.value.titleEventAvailability === TitleEventAvailabilities.UNDEFINED,
        );
        const titleToCheckForFreeSeats = this._getTitlesForCheck(newTitelEvents);

        titleToCheckForFreeSeats.forEach((title) =>
            this._request$.next({ partnerId: request.partnerId, event: title, cacheLong: request.cacheLong }),
        );

        return from(lastValueFrom(super.get()));
    }

    public get(): Observable<Map<string, TitleEventAvailabilityData>> {
        if (this._request) {
            this.load(this._request);
        }
        return super.get();
    }

    protected _shouldLoad(newRequest: ITitleEventAvailabilityRequest): boolean {
        return isPlatformBrowser(this._platformId) && newRequest.events.length > 0 && this._request !== newRequest;
    }

    protected _getRest(): (
        request: ITitleEventAvailabilityRequest | null,
    ) => Observable<Map<string, TitleEventAvailabilityData>> {
        return (request) => {
            const eventIds = request?.events.map((titleEvent) => titleEvent.id) ?? [];
            return this._titleEventRestService
                .checkFreeSeats({
                    partnerId: request?.partnerId,
                    eventIds,
                    cacheLong: request?.cacheLong,
                } as IFreeSeatsRequest)
                .pipe(
                    catchError((err) => {
                        eventIds.forEach((id) => {
                            this._map.get(id)?.next({
                                titleEventAvailability: TitleEventAvailabilities.CURRENTLY_UNAVAILABLE,
                            } as TitleEventAvailabilityData);
                        });

                        return [];
                    }),
                    tap((response) => this._setDecryptedEventIdMap(response)),
                    map((response) => this._mapToResponse(this._updateTitleEventAvailabilityData(response))),
                );
        };
    }

    private _mapToResponse(
        availablity$Map: Map<string, Observable<TitleEventAvailabilityData>>,
    ): Map<string, TitleEventAvailabilityData> {
        const mapEntries: [string, TitleEventAvailabilityData][] = Array.from(availablity$Map.entries()).map(
            ([titleEventId, titleEventAvailabilityData$]) => [
                titleEventId,
                (titleEventAvailabilityData$ as BehaviorSubject<TitleEventAvailabilityData>).value,
            ],
        );
        return new Map(mapEntries);
    }

    private _mapSingleToListTequest(
        singleRequests: ISingleTitleEventAvailabilityRequest[],
    ): ITitleEventAvailabilityRequest {
        return {
            partnerId: singleRequests[0].partnerId,
            events: singleRequests.map((single) => single.event),
            cacheLong: singleRequests.some((r) => r.cacheLong),
        };
    }

    private _mapToTitleEventAvailability(titleEvent: TitleEvent | null): TitleEventAvailabilities {
        if (!titleEvent) {
            return TitleEventAvailabilities.UNDEFINED;
        }
        if (titleEvent.soldOut) {
            return TitleEventAvailabilities.SOLD_OUT;
        }
        if (titleEvent.currentlyUnavailable) {
            return TitleEventAvailabilities.CURRENTLY_UNAVAILABLE;
        }

        if (titleEvent.eventDateStatus === EventDateStatus.BeforeSale) {
            return TitleEventAvailabilities.SELL_OPEN_SOON;
        }
        if (titleEvent.eventDateStatus === EventDateStatus.AfterSale) {
            return TitleEventAvailabilities.SELL_ENDED;
        }
        return TitleEventAvailabilities.CHECK_FREE_SEATS;
    }

    private _updateTitleEventAvailabilityData(
        response: IFreeSeats[],
    ): Map<string, Observable<TitleEventAvailabilityData>> {
        response.forEach(
            (availabilityData: IFreeSeats) =>
                this._map.get(availabilityData.eventId)?.next({
                    titleEventAvailability: availabilityData.hasFreeSeats
                        ? TitleEventAvailabilities.AVAILABLE
                        : TitleEventAvailabilities.SOLD_OUT,
                    ...(availabilityData.minPrice !== undefined &&
                        availabilityData.minPrice >= 0 && { minPrice: availabilityData.minPrice }),
                    ...(availabilityData.isPromoted !== undefined && { isPromoted: availabilityData.isPromoted }),
                    ...(onlyDigitsExp.test(availabilityData.freeSeatsCount ?? '') && {
                        freeSeatsCount: availabilityData.freeSeatsCount,
                    }),
                } as TitleEventAvailabilityData),
        );
        return this._map;
    }

    private _getTitlesForCheck(titles: TitleEvent[]): TitleEvent[] {
        const titleToCheckForFreeSeats: TitleEvent[] = [];
        titles.forEach((titleEvent) => {
            const titleEventAvailablity: TitleEventAvailabilities = this._mapToTitleEventAvailability(titleEvent);
            if (titleEventAvailablity === TitleEventAvailabilities.CHECK_FREE_SEATS) {
                titleToCheckForFreeSeats.push(titleEvent);
            }
            this._updateAvailability(titleEvent.id, titleEventAvailablity);
        });

        return titleToCheckForFreeSeats;
    }

    private _setDecryptedEventIdMap(response: IFreeSeats[]): void {
        response.forEach((titleEventFreeSeats) =>
            this._decryptedEventIdMap.set(titleEventFreeSeats.eventId, titleEventFreeSeats.decryptedEventId),
        );
    }

    public getDecryptedEventId(eventId: string): string | undefined {
        return this._decryptedEventIdMap.get(eventId);
    }

    public find$(eventId: string): Observable<TitleEventAvailabilityData> {
        this._updateAvailability(eventId, this._mapToTitleEventAvailability(null));

        return this._map.get(eventId)?.asObservable() as Observable<TitleEventAvailabilityData>;
    }

    private _updateAvailability(eventId: string, newTitleEventAvailability: TitleEventAvailabilities): void {
        if (this._map.has(eventId)) {
            const titleEventAvailability$ = this._map.get(eventId);
            if (titleEventAvailability$?.value.titleEventAvailability !== newTitleEventAvailability) {
                const minPrice = titleEventAvailability$?.value.minPrice;
                const isPromoted = titleEventAvailability$?.value.isPromoted;
                const freeSeatsCount = titleEventAvailability$?.value.freeSeatsCount;
                titleEventAvailability$?.next({
                    titleEventAvailability: newTitleEventAvailability,
                    minPrice,
                    isPromoted,
                    freeSeatsCount,
                } as TitleEventAvailabilityData);
            }
        } else {
            this._map.set(
                eventId,
                new BehaviorSubject<TitleEventAvailabilityData>({
                    titleEventAvailability: newTitleEventAvailability,
                } as TitleEventAvailabilityData),
            );
        }
    }
}
