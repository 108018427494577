<div class="title-event-container">
  <eb-ticket-panel
    [ngClass]="{ 'title-event-bar__state--unavailable': titleEventIsUnavailable }"
    [noPriceInfo]="!isPromoted && !(minPrice | ebIsNumber)"
  >
    <div
      eb-ticket-panel-stub
      class="eb-title-event-bar__stub"
      [ngClass]="{
        'eb-title-event-bar__stub--thumbnail-hidden': isThumbnailHidden || !titleEvent?.imageLandscape,
        'eb-title-event-bar__stub--date-hidden': titleEvent?.isEventDateHidden
      }"
    >
      @if (addMicroData && !skeletonView && eventJson) {
        <eb-json-ld [type]="'event-data'" [id]="titleEvent!.uniqueId" [json]="eventJson" />
      }
      <div class="eb-title-event-bar__thumbnail">
        <img [src]="titleEvent?.imageLandscape | media" *ngIf="titleEvent?.imageLandscape" [alt]="titleEvent?.title" />
      </div>
      <div *ngIf="!titleEvent?.isEventDateHidden" class="eb-title-event-bar__date">
        <ng-container [ngSwitch]="skeletonView">
          <ng-container *ngSwitchCase="true">
            <p class="h6 eb-skeleton mb-2"></p>
            <div class="eb-skeleton mb-2"></div>
            <div class="eb-skeleton mb-2"></div>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <ng-container *ngIf="titleEvent?.customDateText; else dateTmpl">
              <div class="h5 eb-title-event-bar__custom-date-text">{{ titleEvent?.customDateText }}</div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="eb-title-event-bar__info">
        <ng-container [ngSwitch]="skeletonView">
          <ng-container *ngSwitchCase="true">
            <p class="h6 eb-skeleton mb-2" style="width: 80%"></p>
            <div class="eb-skeleton mb-3"></div>
            <div class="eb-skeleton" style="width: 60%"></div>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <p class="h5 eb-title-event-bar__header">
              <span class="mr-3">{{ titleEvent?.title }}</span> <span class="eb-pill" *ngIf="titleEvent?.lastPlaces">Ostatnie miejsca</span>
            </p>
            <div>
              <span *ngIf="titleEvent?.city">{{ titleEvent?.city }}, </span><span>{{ titleEvent?.placeName }}</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div eb-ticket-panel-counterfoil class="eb-title-event-bar__counterfoil">
      <ng-container *ngIf="skeletonView; then skeletonViewTmpl; else contentViewTmpl" />
      <ng-template #skeletonViewTmpl>
        <div class="flex align-items-center">
          <p class="h6 eb-skeleton mr-2"></p>
          <span class="eb-skeleton eb-skeleton--btn"></span>
        </div>
      </ng-template>

      <ng-template #contentViewTmpl>
        <div *ngIf="titleEvent" ebIntersection [ebOnlyFirst]="true" (intersecting)="checkAvailability()">
          <ng-container [ngSwitch]="titleEventAvailability">
            <ng-container *ngSwitchCase="TitleEventAvailabilities.AVAILABLE">
              <div
                class="eb-title-event-bar__counterfoil-content"
                [ngClass]="{ 'eb-title-event-bar__counterfoil-content--row': isThumbnailHidden || !!titleEvent.imageLandscape }"
              >
                <p *ngIf="freeSeatsCount" class="eb-free-seats-count eb-size--tiny mb-0">
                  @if (+freeSeatsCount > 10) {
                    {{ 'libs.title-event-bar.seatsAvailable' | transloco }}
                    <span class="font-bold">{{ freeSeatsCount }} {{ 'libs.title-event-bar.pieces' | transloco }}</span>
                  } @else {
                    <span class="font-bold">{{ 'libs.title-event-bar.lastPieces' | transloco }}</span>
                  }
                </p>
                <eb-buy-ticket-button
                  *ngIf="titleEvent"
                  [titleEvent]="titleEvent"
                  [partnerId]="partnerId"
                  [tokenCheckSum]="tokenCheckSum"
                  [virtualPageView]="virtualPageView"
                  [row]="isThumbnailHidden || !!titleEvent.imageLandscape"
                  [isTitlePage]="isTitlePage"
                  [barRelative]="true"
                  [isPromoted]="isPromoted"
                >
                  <svg-icon key="ticket" /> {{ 'libs.title-event-bar.buyTickets' | transloco }}
                  <span *ngIf="(minPrice | ebIsNumber) && !(isPromoted && isTitlePage)" class="eb-title-event-bar__price">
                    {{ 'libs.title-event-bar.from' | transloco }} {{ minPrice | ebCurrency: 'PLN' }}
                  </span>
                  <span *ngIf="isPromoted && isTitlePage" class="eb-title-event-bar__on-promotion">{{
                    'promotion.onPromotion' | transloco
                  }}</span>

                  <ng-container eb-buy-ticket-queue>
                    <p class="eb-title-event-bar__queue-text eb-size--tiny">
                      <span [innerHtml]="'libs.title-event-bar.redirectToShop' | transloco"></span>
                    </p>
                  </ng-container>
                </eb-buy-ticket-button>
                <p *ngIf="isPromoted || (minPrice | ebIsNumber)" class="eb-sales-info eb-size--tiny">
                  {{ (isPromoted ? 'promotion.promotionDetails' : 'libs.title-event-bar.allFeesIncluded') | transloco }}
                </p>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="TitleEventAvailability.CURRENTLY_UNAVAILABLE">
              <ng-template
                [ngTemplateOutlet]="fanAlertTmpl"
                [ngTemplateOutletContext]="{ ticketStateLabel: 'libs.title-event-bar.ticketsUnavailable' }"
              />
            </ng-container>
            <ng-container *ngSwitchCase="TitleEventAvailability.SOLD_OUT">
              <ng-template
                [ngTemplateOutlet]="fanAlertTmpl"
                [ngTemplateOutletContext]="{ ticketStateLabel: 'libs.title-event-bar.soldOut' }"
              />
            </ng-container>
            <ng-container *ngSwitchCase="TitleEventAvailability.SELL_OPEN_SOON">
              <ng-template
                [ngTemplateOutlet]="fanAlertTmpl"
                [ngTemplateOutletContext]="{ ticketStateLabel: 'libs.title-event-bar.sellOpenSoon' }"
              />
            </ng-container>
            <ng-container *ngSwitchCase="TitleEventAvailability.SELL_ENDED">
              <ng-template
                [ngTemplateOutlet]="fanAlertTmpl"
                [ngTemplateOutletContext]="{ ticketStateLabel: 'libs.title-event-bar.sellEnded' }"
              />
            </ng-container>
            <ng-container *ngSwitchCase="TitleEventAvailability.CHECK_FREE_SEATS">
              <svg-icon key="loading" />
            </ng-container>
            <ng-container *ngSwitchCase="TitleEventAvailability.UNDEFINED">
              <svg-icon key="loading" />
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </eb-ticket-panel>
</div>

<ng-template let-ticketStateLabel="ticketStateLabel" #fanAlertTmpl>
  <div class="eb-title-event-bar__fan-alert">
    <span class="eb-size--tiny fw-bold">{{ ticketStateLabel | transloco }}.</span>
    <span *ngIf="showFanAlert" class="eb-size--tiny fw-bold mb-2">{{ 'libs.title-event-bar.subscribeToNewsletter' | transloco }}.</span>
    <eb-fan-alert
      *ngIf="showFanAlert"
      [fanAlertParams]="fanAlertParams"
      observedButtonColor="purple"
      observedButtonType="primary"
      [labelVisible]="true"
    />
  </div>
</ng-template>

<ng-template #dateTmpl>
  <div class="h6 eb-title-event-bar__date__day">{{ titleEvent?.date | date: 'EEEE' : undefined : activeLanguage }}</div>
  <div class="eb-title-event-bar__dateTmpl">{{ titleEvent?.date | date: 'dd.MM.yyyy' : undefined : activeLanguage }}</div>
  <div class="eb-title-event-bar__dateTmpl">{{ titleEvent?.date | date: 'HH:mm' : undefined : activeLanguage }}</div>
</ng-template>
