import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output, PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { EbQuickSearchInputGroupComponent } from '../quick-search-input-group/quick-search-input-group.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'eb-search-drawer',
    templateUrl: './search-drawer.component.html',
    styleUrls: ['./search-drawer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbSearchDrawerComponent {
    private _platformId = inject(PLATFORM_ID);
    private _isVisible = false;

    @Input({ required: true }) public set isVisible(isVisible: boolean) {
        if (isVisible) {
            this._show();
        } else {
            this._close();
        }
    }

    public get isVisible(): boolean {
        return this._isVisible;
    }

    @Output() isVisibleChange = new EventEmitter<boolean>();
    @ViewChild('container') readonly containerElement!: ElementRef<HTMLDivElement>;
    @ViewChild(EbQuickSearchInputGroupComponent)
    readonly ebQuickSearchInputGroupComponent!: EbQuickSearchInputGroupComponent;

    private _show(): void {
        this._isVisible = true;
        if (this.isVisibleChange.observed) {
            this.isVisibleChange.emit(this.isVisible);
        }

        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => this.ebQuickSearchInputGroupComponent.focus(), 300);
        }
    }

    private _close(): void {
        this._isVisible = false;
        if (this.isVisibleChange.observed) {
            this.isVisibleChange.emit(this.isVisible);
        }
    }
}
