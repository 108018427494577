import { isPlatformServer } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GtmService } from '@e-bilet/gtm';
import {
  CookieConsent,
  CookiesPolicyService
} from 'libs/cookies-policy/src/lib/cookies-policy.service';
import { Observable, Subject } from 'rxjs';
import { CookieName, CookiesService } from '../../../../../libs/cookies-policy/src/lib/cookies.service';
import { DeviceService } from '../../../../../libs/device/src/lib/device.service';
import { BaseVirtualPageView } from '../../../../../libs/gtm/src/lib/virtual-page-view';
import { environment } from '../../environments/environment';
import { RandomNumberHelper } from '../helpers/random-number.helper';
import { IFrame } from '../rest-api/models/iframe';
import { TitleEvent } from '../rest-api/models/title-page.model';
import { TitleEventAvailabilityStoreService } from '../stores/title-event-availability-store.service';
import { IframeService } from './iframe.service';
import { InternationalizationService } from './internationalization.service';

export interface ShopQueue {
    eventId: string;
    queueDelay: number;
}

@Injectable({
    providedIn: 'root',
})
export class ShopQueueService implements OnDestroy {
    private readonly _gtmService = inject(GtmService);
    private readonly _i18nService = inject(InternationalizationService);
    private readonly _cookiesService = inject(CookiesService);
    private readonly _titleEventAvailabilityStoreService = inject(TitleEventAvailabilityStoreService);
    private readonly _iframeService = inject(IframeService);
    private readonly _deviceService = inject(DeviceService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _cookiesPolicyService = inject(CookiesPolicyService);

    private _queueTimeout: number | undefined;
    private _iframe: IFrame | undefined;
    private _isCookiesConsentAccepted = false;
    private readonly _setQueue$ = new Subject<ShopQueue>();
    private readonly _redirect$ = new Subject<string>();
    private readonly _setBrandingCookie$ = new Subject<string>();

    public get setQueue$(): Observable<ShopQueue> {
        return this._setQueue$.asObservable();
    }

    public get redirect$(): Observable<string> {
        return this._redirect$.asObservable();
    }

    public get setBrandingCookie$(): Observable<string> {
        return this._setBrandingCookie$.asObservable();
    }

    constructor() {
        this._iframeService.iframe$.pipe(takeUntilDestroyed()).subscribe((iframe: IFrame | undefined) => {
            this._iframe = iframe;
        });

        this._cookiesPolicyService.cookiesPolicyConsent$
            .pipe(takeUntilDestroyed())
            .subscribe((cookiesConsent: CookieConsent|undefined) => {
                this._isCookiesConsentAccepted = !!cookiesConsent?.marketing;
            });
    }

    public queueRedirectToShop(
        titleEvent: TitleEvent,
        partnerId: string | undefined,
        tokenCheckSum: string | undefined,
        ctrlClick: boolean,
        virtualPageView: BaseVirtualPageView | undefined,
    ): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }

        const queueDelay = RandomNumberHelper.random(titleEvent.webShopMaxDelay);
        this._setQueue$.next({
            eventId: titleEvent.id,
            queueDelay,
        });

        clearTimeout(this._queueTimeout);
        this._queueTimeout = window.setTimeout(() => {
            this._redirectToShop(titleEvent, partnerId, tokenCheckSum, ctrlClick, virtualPageView);
        }, queueDelay * 1000);
    }

    private _redirectToShop(
        titleEvent: TitleEvent,
        partnerId: string | undefined,
        tokenCheckSum: string | undefined,
        ctrlClick: boolean,
        virtualPageView: BaseVirtualPageView | undefined,
    ): void {
        this._redirect$.next(titleEvent.id);
        if (virtualPageView) {
            this._gtmService.setVirtualPageView(virtualPageView.getParams(titleEvent));
        }

        const titleEventId = this._titleEventAvailabilityStoreService.getDecryptedEventId(titleEvent.id);
        if (!titleEventId) {
            return;
        }

        const redirectUrl = this._getRedirectUrl(
            titleEventId,
            partnerId ?? titleEvent.partnerId,
            titleEvent.isTradedoublerEnabled,
            tokenCheckSum,
        );
        this._setBrandingCookie$.next(titleEventId);
        this._iframeService.sendHeightPostMessage(100, 'vh');

        // dla ctrlClick oraz w iframe na safari/ios otwiera sklep w nowej karcie
        if (ctrlClick || (this._iframe && this._deviceService.checkIfUserAgentIsIosOrSafari())) {
            window.open(redirectUrl, '_blank');
        } else {
            window.location.href = redirectUrl;
        }
    }

    private _getRedirectUrl(
        titleEventId: string,
        partnerId: string | undefined,
        tradedoubler?: boolean,
        tokenCheckSum?: string,
    ): string {
        const partnerParam = this._getPartnerParam(partnerId, tradedoubler);
        const internationalizationRoute = this._i18nService.isEnglishActive ? 'en/' : '';
        const params = this._getUrlParams(tokenCheckSum, partnerParam);

        return `${environment.shopUrl}/${internationalizationRoute}${partnerParam}${titleEventId}${params}`;
    }

    private _getUrlParams(tokenCheckSum?: string, partnerParam?: string): string {
        let params = new HttpParams();
        if (tokenCheckSum) {
            params = params.set('cs', tokenCheckSum);
        }

        if (partnerParam?.includes(environment.tradedoublerPrefix)) {
            params = params.set('fromPortal', true);
        }

        if (this._iframe) {
            params = params.set('forIFrame', this._iframe.domain);
            params = params.set('shopMode', this._iframe.type);
            params = params.set('portalRedirectUrl', window.location.origin + window.location.pathname);

            if (this._iframe.primaryColor) {
                params = params.set('primaryColor', this._iframe.primaryColor);
            }

            if (this._iframe.activeBtnTxtColor) {
                params = params.set('activeBtnTxtColor', this._iframe.activeBtnTxtColor);
            }

            if (this._iframe.footerVisible) {
                params = params.set('footerVisible', this._iframe.footerVisible);
            }
        }

        if (params.keys().length) {
            return '?' + params.toString();
        }

        return '';
    }

    private _getPartnerParam(partnerId: string | undefined, isTradedoublerEnabled: boolean | undefined): string {
        if (partnerId) {
            return `${partnerId}/`;
        }
        if (!isTradedoublerEnabled) {
            return '';
        }

        if (this._isCookiesConsentAccepted) {
            const tduid = this._cookiesService.getCookieValue(CookieName.Tradedoubler);
            const tradedoublerParamId = this._cookiesService.getCookieValue(CookieName.TradedoublerPartnerId);

            if (tduid && tradedoublerParamId) {
                return `${tradedoublerParamId}/`;
            }
        } else if (!this._isCookiesConsentAccepted && sessionStorage.getItem('tduid')) {
            return `${environment.tradedoublerPrefix}/`;
        }

        return '';
    }

    public ngOnDestroy(): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }

        clearTimeout(this._queueTimeout);
    }
}
