import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SyneriseService } from '@e-bilet/synerise';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AdditionalCarousel } from './models/additional-carousel.model';
import { Category } from './models/category.model';
import { IDontMissTile } from './models/dont-miss-tile.interface';
import { FooterDto } from './models/footer.model';
import { City, Province } from './models/province.model';
import { SearchRequest } from './models/search-request.model';
import { SearchResponse } from './models/search-response.model';
import { IHomePage } from './models/seo-info.model';
import { TitleGroup } from './models/title-group.enum';
import { Title } from './models/title.model';

@Injectable({
    providedIn: 'root',
})
export class HomeRestService {
    private readonly _httpClient = inject(HttpClient);
    private readonly _syneriseService = inject(SyneriseService);
    private readonly _baseUrl = '/Home';

    public getSeo(): Observable<IHomePage> {
        return this._httpClient.get<IHomePage>(`${environment.portalApi}${this._baseUrl}/Seo`);
    }

    public getFeaturedEvents(size: number = 20): Observable<Title[]> {
        let params = new HttpParams();
        params = params.append('size', size);
        return this._httpClient.get<Title[]>(`${environment.portalApi}${this._baseUrl}/FeaturedEvents`, { params });
    }

    public getDealsEvents(): Observable<AdditionalCarousel> {
        return this._httpClient.get<AdditionalCarousel>(`${environment.portalApi}${this._baseUrl}/PromotedOnSGeBilet`);
    }

    public getAdditionalCarousels(): Observable<AdditionalCarousel[]> {
        return this._httpClient.get<AdditionalCarousel[]>(
            `${environment.portalApi}${this._baseUrl}/AdditionalCarousels`,
        );
    }

    public getForYouEvents(size: number = 20): Observable<Title[]> {
        return this._syneriseService.getClient().pipe(
            switchMap((client) => {
                let params = new HttpParams();
                const userId = client?.uuid;

                if (userId && userId !== 'null') {
                    params = params.append('userId', client.uuid);
                } else {
                    return of([]);
                }

                if (size) {
                    params = params.append('size', size);
                }

                return this._httpClient.get<Title[]>(`${environment.portalApi}${this._baseUrl}/ForYou`, {
                    params,
                });
            }),
        );
    }

    public getCategories(): Observable<Category[]> {
        return this._httpClient.get<Category[]>(`${environment.portalApi}${this._baseUrl}/GetCategoryList`);
    }

    public getOnTime(size: number = 20): Observable<Title[]> {
        let params = new HttpParams();
        params = params.append('size', size);
        return this._httpClient.get<Title[]>(`${environment.portalApi}${this._baseUrl}/GetOnTime`, { params });
    }

    public getLastMinute(size: number = 20): Observable<Title[]> {
        let params = new HttpParams();
        params = params.append('size', size);
        return this._httpClient.get<Title[]>(`${environment.portalApi}${this._baseUrl}/lastMinute`, { params });
    }

    public getVisitedTitles(): Observable<Title[]> {
        return this._syneriseService.getClient().pipe(
            switchMap((client) => {
                let params = new HttpParams();
                const userId = client?.uuid;

                if (userId && userId !== 'null') {
                    params = params.append('userId', client.uuid);
                } else {
                    return of([]);
                }

                return this._httpClient.get<Title[]>(`${environment.portalApi}${this._baseUrl}/GetVisitedTitles`, {
                    params,
                });
            }),
        );
    }

    public getCities(): Observable<City[]> {
        return this._httpClient.get<City[]>(`${environment.portalApi}${this._baseUrl}/Cities`);
    }

    public getCitiesTree(): Observable<Province[]> {
        return this._httpClient.get<Province[]>(`${environment.portalApi}${this._baseUrl}/CitiesTree`);
    }

    public getEvents(group: TitleGroup | null, size: number = 20): Observable<Title[]> {
        let params = new HttpParams();
        params = params.append('size', size);
        return this._httpClient.get<Title[]>(
            `${environment.portalApi}${this._baseUrl}/Events/${group ?? TitleGroup.News}`,
            { params },
        );
    }

    public search(request: SearchRequest): Observable<SearchResponse> {
        let params: HttpParams;
        if (request) {
            const { localization, ...restOfrequest } = request;
            params = new HttpParams({ fromObject: { ...restOfrequest } });
            if (localization) {
                params.append('localization.latitude', localization.latitude);
                params.append('localization.longitude', localization.longitude);
            }
        } else {
            params = new HttpParams();
        }
        return this._httpClient.get<SearchResponse>(`${environment.portalApi}${this._baseUrl}/SearchProposed`, {
            params,
        });
    }

    public getProposed(): Observable<SearchResponse> {
        return this._httpClient.get<SearchResponse>(`${environment.portalApi}${this._baseUrl}/GetProposed`);
    }

    public getFooter(): Observable<FooterDto> {
        return this._httpClient.get<FooterDto>(`${environment.portalApi}${this._baseUrl}/Footer`);
    }

    public getDontMiss(): Observable<IDontMissTile[]> {
        return this._httpClient.get<IDontMissTile[]>(`${environment.portalApi}${this._baseUrl}/GetDoNotMiss`);
    }

    public cookiesPolicyShowed(): Observable<any> {
        return this._httpClient.put(`${environment.portalApi}${this._baseUrl}/CookiesShowed`, {});
    }

    public acceptCookiesPolicy(): Observable<any> {
        return this._httpClient.put(`${environment.portalApi}${this._baseUrl}/CookiesAllowed`, {});
    }

    public rejectCookiesPolicy(): Observable<any> {
        return this._httpClient.put(`${environment.portalApi}${this._baseUrl}/CookiesNotAllowed`, {});
    }

    public checkDomainIsEnabledForIframe(path: string, domain: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('Domain', domain);
        params = params.append('Address', path);

        return this._httpClient.get<boolean>(`${environment.portalApi}/iframe/available`, { params });
    }
}
