import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslocoModule } from '@ngneat/transloco';
import { JsonLdModule } from '../../../../../libs/json-ld/src';
import { UiAccordionModule } from '../../../../../libs/ui-accordion/src';
import { UiAffixModule } from '../../../../../libs/ui-affix/src';
import { UiButtonsModule } from '../../../../../libs/ui-buttons/src';
import { UiBuyTicketButtonModule } from '../../../../../libs/ui-buy-ticket-button/src';
import { UiCarouselModule } from '../../../../../libs/ui-carousel/src';
import { UiDatePickerModule } from '../../../../../libs/ui-date-picker/src';
import { UiDialogModule } from '../../../../../libs/ui-dialog/src';
import { UiDrawerModule } from '../../../../../libs/ui-drawer/src';
import { UiDropdownModule } from '../../../../../libs/ui-dropdown/src/lib/ui-dropdown.module';
import { UiFanAlertModule } from '../../../../../libs/ui-fan-alert/src/lib/ui-fan-alert.module';
import { UiFormItemModule } from '../../../../../libs/ui-form-item/src/lib/ui-form-item.module';
import { UiHeartButtonModule } from '../../../../../libs/ui-heart-button/src';
import { UiListViewToggleButtonModule } from '../../../../../libs/ui-list-view-toggle-button/src';
import { UiOverlayModule } from '../../../../../libs/ui-overlay/src';
import { UiPagedListModule } from '../../../../../libs/ui-paged-list/src';
import { UiPipeModule } from '../../../../../libs/ui-pipe/src';
import { UiProgressBarModule } from '../../../../../libs/ui-progress-bar/src';
import { UiScrollbarModule } from '../../../../../libs/ui-scrollbar/src';
import { UiSelectModule } from '../../../../../libs/ui-select/src';
import { UiSpinnerModule } from '../../../../../libs/ui-spinner/src';
import { UiTabsModule } from '../../../../../libs/ui-tabs/src';
import { UiTitleCardModule } from '../../../../../libs/ui-title-card/src';
import { UiTitleEventBarModule } from '../../../../../libs/ui-title-event-bar/src/lib/ui-title-event-bar.module';
import { UiTopbarsModule } from '../../../../../libs/ui-topbars/src';
import { UiTreeSelectModule } from '../../../../../libs/ui-tree-select/src';
import { UiArticleCardModule } from '../../../../../libs/ui-article-card/src/lib/ui-article-card.module';
import { EbBottomNavBarComponent } from './components/bottom-nav-bar/bottom-nav-bar.component';
import { EbCalendarComponent } from './components/calendar/calendar.component';
import { EbCategoryMegaMenuDrawerComponent } from './components/category-mega-menu-drawer/category-mega-menu-drawer.component';
import { EbCategoryMegaMenuComponent } from './components/category-mega-menu/category-mega-menu.component';
import { EbCounterComponent } from './components/counter/counter.component';
import { DontMissComponent } from './components/dont-miss/dont-miss.component';
import { EbFaqSectionComponent } from './components/faq-section/faq-section.component';
import { FooterIframeComponent } from './components/footer-iframe/footer-iframe.component';
import { EbFooterComponent } from './components/footer/footer.component';
import { EbGalleryKeyboardNavigationDirective } from './components/gallery/gallery-keyboard-navigation.directive';
import { EbGalleryComponent } from './components/gallery/gallery.component';
import { EbLocationTreeSelectComponent } from './components/location-tree-select/location-tree-select.component';
import { EbLogoComponent } from './components/logo/logo.component';
import { EbMapComponent } from './components/map/map.component';
import { EbNoEventInfoComponent } from './components/no-event-info/no-event-info.component';
import { PageDescriptionComponent } from './components/page-description/page-description.component';
import { EbPageHeaderComponent } from './components/page-header/page-header.component';
import { EbPortalMasterPageComponent } from './components/portal-master-page/portal-master-page.component';
import { EbQuickSearchInputGroupComponent } from './components/quick-search-input-group/quick-search-input-group.component';
import { ScrollButtonComponent } from './components/scroll-button/scroll-button.component';
import { EbSearchDrawerComponent } from './components/search-drawer/search-drawer.component';
import { EbSearchInputComponent } from './components/search-input/search-input.component';
import { EbSearchSelectComponent } from './components/search-select/search-select.component';
import { EbSeoSectionLinkComponent } from './components/seo-section-link/seo-section-link.component';
import { EbShareButtonComponent } from './components/share-button/share-button.component';
import { SocialButtonComponent } from './components/social-button/social-button.component';
import { TitleDontMissComponent } from './components/title-dont-miss/title-dont-miss.component';
import { TitleEventCalendarItemComponent } from './components/title-event-calendar-item/title-event-calendar-item.component';
import { EbTitleEventCalendarComponent } from './components/title-event-calendar/title-event-calendar.component';
import { EbTitleEventListComponent } from './components/title-event-list/title-event-list.component';
import { EbTitleEventTimeSlotComponent } from './components/title-event-time-slot/title-event-time-slot.component';
import { EbDoubleCardsDetailsComponent } from './components/double-cards/double-cards-details/double-cards-details.component';
import { EbDoubleCardsDialogComponent } from './components/double-cards/double-cards-dialog/double-cards-dialog.component';
import { EbDoubleCardsDrawerComponent } from './components/double-cards/double-cards-drawer/double-cards-drawer.component';
import { EbDoubleCardsComponent } from './components/double-cards/double-cards.component';
@NgModule({
    declarations: [
        EbLocationTreeSelectComponent,
        EbSearchInputComponent,
        EbSearchSelectComponent,
        EbQuickSearchInputGroupComponent,
        EbFooterComponent,
        EbBottomNavBarComponent,
        EbCategoryMegaMenuDrawerComponent,
        EbCategoryMegaMenuComponent,
        EbPortalMasterPageComponent,
        EbCounterComponent,
        EbLogoComponent,
        EbCalendarComponent,
        EbTitleEventCalendarComponent,
        EbShareButtonComponent,
        EbGalleryComponent,
        EbGalleryKeyboardNavigationDirective,
        EbTitleEventTimeSlotComponent,
        EbTitleEventListComponent,
        EbPageHeaderComponent,
        SocialButtonComponent,
        PageDescriptionComponent,
        EbSearchDrawerComponent,
        EbMapComponent,
        DontMissComponent,
        EbSeoSectionLinkComponent,
        EbFaqSectionComponent,
        EbNoEventInfoComponent,
        TitleEventCalendarItemComponent,
        EbDoubleCardsComponent,
        EbDoubleCardsDialogComponent,
        EbDoubleCardsDrawerComponent,
        EbDoubleCardsDetailsComponent,
        TitleDontMissComponent,
        FooterIframeComponent,
        ScrollButtonComponent,
    ],
    exports: [
        EbLocationTreeSelectComponent,
        EbSearchInputComponent,
        EbSearchSelectComponent,
        EbQuickSearchInputGroupComponent,
        EbFooterComponent,
        EbBottomNavBarComponent,
        EbCategoryMegaMenuDrawerComponent,
        EbPortalMasterPageComponent,
        LayoutModule,
        EbCounterComponent,
        UiPipeModule,
        EbLogoComponent,
        EbCalendarComponent,
        EbTitleEventCalendarComponent,
        EbShareButtonComponent,
        EbGalleryComponent,
        EbGalleryKeyboardNavigationDirective,
        EbTitleEventTimeSlotComponent,
        EbTitleEventListComponent,
        EbPageHeaderComponent,
        SocialButtonComponent,
        PageDescriptionComponent,
        EbSearchDrawerComponent,
        EbMapComponent,
        DontMissComponent,
        EbSeoSectionLinkComponent,
        EbFaqSectionComponent,
        EbNoEventInfoComponent,
        TitleDontMissComponent,
        SvgIconComponent,
        EbDoubleCardsComponent,
        EbDoubleCardsDialogComponent,
        EbDoubleCardsDrawerComponent,
        EbDoubleCardsDetailsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        UiTopbarsModule,
        UiSelectModule,
        UiTreeSelectModule,
        UiDatePickerModule,
        UiButtonsModule,
        UiDrawerModule,
        UiCarouselModule,
        UiDialogModule,
        UiOverlayModule,
        LayoutModule,
        UiPipeModule,
        UiFormItemModule,
        TranslocoModule,
        UiTabsModule,
        UiListViewToggleButtonModule,
        UiTitleEventBarModule,
        UiPagedListModule,
        UiAffixModule,
        UiHeartButtonModule,
        UiProgressBarModule,
        UiSpinnerModule,
        UiBuyTicketButtonModule,
        UiAccordionModule,
        UiFanAlertModule,
        UiAccordionModule,
        UiDropdownModule,
        JsonLdModule,
        UiTitleCardModule,
        UiScrollbarModule,
        SvgIconComponent,
        UiArticleCardModule,
    ],
})
export class SharedModule {}
