<div class="eb-double-cards-container flex justify-content-between gap-5 mb-5">
  @if (type === doubleCardsTypes.DEAL_ZONE) {
    <ng-container *ngTemplateOutlet="doubleCardsContent; context: { type: doubleCardsTypes.DEAL_ZONE, description: 'image'}" />
    <ng-container *ngTemplateOutlet="doubleCardsContent; context: { type: doubleCardsTypes.DEAL_ZONE, header: 'shared.dealZone.dealZoneTitle' | transloco, description: 'shared.dealZone.dealZoneSubTitle' | transloco }" />
  } @else {
    <ng-container *ngTemplateOutlet="doubleCardsContent; context: { type: doubleCardsTypes.PAY_BY_INSTALMENTS, header: 'shared.delayedPayments.payByInstalments' | transloco, description: 'shared.delayedPayments.conveniently' | transloco }" />
    <ng-container *ngTemplateOutlet="doubleCardsContent; context: { type: doubleCardsTypes.PAYPO, header: 'shared.delayedPayments.buyNowPayLater' | transloco, description: 'shared.delayedPayments.giveYourselfTimeForFun' | transloco }" />
  }
</div>

<ng-template #doubleCardsContent let-type="type" let-header="header" let-description="description" let-descriptionTemplate="descriptionTemplate">
  @if (description === 'image') {
    <div class="eb-double-cards flex flex-1 border-1 border-solid min-w-0  gap-1 md:gap-3" (click)="openDrawer(type)">
      <img class="eb-deal-zone-image"  src="/assets/deal-zone-wallpaper.webp"/>
    </div>
  } @else {
    <div class="eb-double-cards flex flex-1 align-items-center border-1 border-solid min-w-0 px-2 md:px-4 py-4 md:py-5 gap-1 md:gap-3 justify-content-center md:justify-content-start" (click)="openDrawer(type)">
      <div class="eb-double-cards__icon flex ml-2 mr-1 md:mx-1"><svg-icon [key]="type"/></div>
      <div class="eb-double-cards__content md:mx-2">
        <p class="eb-double-cards__content-header font-bold text-sm md:text-xl mb-0 md:mb-2">{{ header }}</p>
        @if (!isMobile && description) {
          <p class="mb-3">{{ description }}</p>
          <a eb-button ebType="text" class="fw-bold eb-double-cards__content-more cursor-pointer w-max" (click)="openDialog(type)"> {{ 'shared.delayedPayments.findOutMore' | transloco }} <svg-icon key="chevron-right"/></a>
        }
      </div>
    </div>
  }
</ng-template>

@if (isDialogOpen) {
  <eb-double-cards-dialog (closeDialogEvent)="closeDialog()">
    <eb-double-cards-details [type]="openDetailsType" [windowType]="doubleCardsWindowTypes.DIALOG" (closeDialogEvent)="closeDialog()" />
  </eb-double-cards-dialog>
}


<eb-double-cards-drawer [(isVisible)]="isDoubleCardsDrawerVisible">
  <eb-double-cards-details [type]="openDetailsType" [windowType]="doubleCardsWindowTypes.DRAWER" />
</eb-double-cards-drawer>
