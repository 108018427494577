import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject, PLATFORM_ID
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { DRAWER_ANIMATE_DURATION, NzDrawerComponent } from 'ng-zorro-antd/drawer';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

export declare type EbDrawerPlacement = 'left' | 'right' | 'top' | 'bottom';

@Component({
    selector: 'eb-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: ['./drawer.component.less'],
    host: { ngSkipHydration: 'true' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbDrawerComponent {
    private _platformId = inject(PLATFORM_ID);
    private readonly _router = inject(Router);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    private _isVisible = false;
    readonly visibilityChange: Subject<boolean> = new Subject();

    @Input() public set isVisible(value: boolean) {
        const normValue = value || false;

        if (normValue !== this._isVisible) {
            this._isVisible = normValue;
            this.visibilityChange.next(this._isVisible);
            if (this._isVisible) {
                this.nzDrawerComponent.open();
            } else {
                this.nzDrawerComponent.close();
            }
        }
    }

    public get isVisible(): boolean {
        return this._isVisible;
    }

    @Input() closable = false;
    @Input() mask = true;
    @Input() placement: EbDrawerPlacement = 'top';
    @Input() footer?: string | TemplateRef<any>;
    @Input() header?: string | TemplateRef<any>;
    @Input() width: string | number = '';
    @Input() height: string | number = 'auto';
    @Input() bodyStyle: any = { padding: '0' };
    @Input() maskStyle?: any;
    @Input() wrapClassName?: string;

    @Output() readonly isVisibleChange = new EventEmitter<boolean>();
    @ViewChild(NzDrawerComponent, { static: true }) readonly nzDrawerComponent!: NzDrawerComponent;

    constructor() {
        this._router.events
            .pipe(
                filter((e) => e instanceof NavigationStart && this.isVisible),
                takeUntilDestroyed(),
            )
            .subscribe((e) => {
                this.close();
                // przejście na nową strone trwa krócej niż zamknięcie drawera
                // dlatego opóźniamy scrollowanie na górę strony o animację zamknięcia
                // przydatne w przypadku wejścia na strone na której już byliśmy
                if (isPlatformBrowser(this._platformId)) {
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'instant' as ScrollBehavior,
                        });
                    }, this._getAnimationDuration() + 1);
                }
            });
    }

    public show(): void {
        if (!this.isVisible) {
            this.isVisible = true;
            if (this.isVisibleChange.observed) {
                this.isVisibleChange.emit(this.isVisible);
            }
            this._changeDetectorRef.detectChanges();
        }
    }

    public close(): void {
        if (this.isVisible) {
            this.isVisible = false;
            if (this.isVisibleChange.observed) {
                this.isVisibleChange.emit(this.isVisible);
            }
            this._changeDetectorRef.detectChanges();
        }
    }

    private _getAnimationDuration(): number {
        return this.nzDrawerComponent.nzNoAnimation ? 0 : DRAWER_ANIMATE_DURATION;
    }
}
