<eb-topbar #topbarEl [backgroundColor]="branding?.headerColor" *ngIf="!iframe">
  <a (click)="redirect($event)" class="mr-5" [href]="homeUrl">
    <eb-logo [imageUrl]="branding?.headerImage"></eb-logo>
  </a>

  <ng-container *ngIf="!branding; then defaultView; else dropdownTriggerWrapper"></ng-container>
</eb-topbar>
<div #page class="eb-page">
  <div #megaMenuOverlay class="mega-menu-overlay eb-fade-out" [ngClass]="{ 'eb-fade-in': menuVisible, 'eb-fade-out': !menuVisible }">
    <div *ngIf="!isMobile" class="mega-menu-overlay-content">
      <eb-category-mega-menu [menuVisible]="menuVisible"></eb-category-mega-menu>
    </div>
  </div>

  <eb-overlay-panel #overlayPanel [overlayStyle]="'eb-title-event-calendar-item-overlay-panel'">
    <ng-template let-data>
      <div class="search-overlay-panel">
        <div class="search-overlay-panel-content">
          <button eb-button ebType="secondary" [ebGhost]="true" ebSize="large" (click)="overlayPanel.close()">
            <svg-icon key="chevron-left"></svg-icon>
          </button>
          <eb-quick-search-input-group [autoFocus]="true" theme="light"></eb-quick-search-input-group>
        </div>
      </div>
    </ng-template>
  </eb-overlay-panel>

  <router-outlet />
</div>
<eb-footer-iframe *ngIf="iframe?.footerVisible" [dark]="iframe?.type === iframeTypeEnum.DARK" />
<eb-footer *ebIsBrowser [branding]="branding" />

<eb-bottom-nav-bar *ngIf="!iframe" class="eb-lg-hidden"/>

<ng-template #defaultView>
  <button eb-button ebType="secondary" class="eb-lg-visible mr-2" (click)="toggleMegaMenu()">
    <svg-icon class="icon--lg-root-relative" [key]="menuVisible ? 'close' : 'grid'" />
    {{ 'shared.portal-master-page.categories' | transloco }}
  </button>

  <button
    *ngIf="isQuickSearchVisible && !isMobile"
    eb-button
    attr.aria-label="{{ 'shared.portal-master-page.search' | transloco }}"
    ebType="secondary"
    [ebGhost]="true"
    class="mr-2"
    [ebOverlay]="overlayPanel"
    [ebOverlayGlobal]="true"
  >
    <svg-icon class="icon--lg-root-relative" key="search"></svg-icon>
  </button>

  <div *ngIf="isSimpleSearchVisible && !isMobile">
    <eb-search-select />
  </div>

  <a eb-button ebType="secondary" [ebGhost]="true" class="ml-auto topbar-link" [href]="blogUrl">
    <svg-icon class="icon--lg-root-relative" key="mdn-player" />
    <span class="eb-lg-visible ml-2"> eBilet NOW </span>
  </a>

  <a
    eb-button
    ebType="secondary"
    [ebGhost]="true"
    class="topbar-link"
    [routerLink]="[internationalizationService.isEnglishActive ? '/en' : '/', 'centrum-pomocy', 'organizator']"
  >
    <svg-icon class="icon--lg-root-relative" key="collaboration" />
    <span class="eb-lg-visible ml-2">
      {{ 'shared.portal-master-page.collaboration' | transloco }}
    </span>
  </a>

  <a
    eb-button
    ebType="secondary"
    [ebGhost]="true"
    class="topbar-link"
    [routerLink]="[internationalizationService.isEnglishActive ? '/en' : '/', 'newsletter']"
  >
    <svg-icon class="icon--lg-root-relative" key="envelope" />
    <span class="eb-lg-visible ml-2"> Newsletter </span>
  </a>

  <ng-container *ngIf="!branding; then dropdownTriggerWrapper" />

  <button
    eb-button
    attr.aria-label="{{ 'shared.portal-master-page.yourAccount' | transloco }}"
    ebType="secondary"
    [ebGhost]="true"
    class="eb-lg-visible mr-2"
    (click)="goToLoginPage()"
  >
    <svg-icon class="icon--lg-root-relative" key="user" />
  </button>
</ng-template>

<ng-template #dropdownTriggerWrapper>
  <button
    #dropdownTrigger="ebDropdownTriggerForDirective"
    eb-button
    [active]="dropdownTrigger.isDropdownOpen"
    attr.aria-label="{{ 'shared.portal-master-page.selectLanguage' | transloco }}"
    [ebDropdownTriggerFor]="languageChoiceDropdown"
    ebType="secondary"
    [ebGhost]="true"
    class="mr-2"
  >
    <span class="ml-2">{{ internationalizationService.activeLanguage | uppercase }}</span>
    <svg-icon class="icon-smaller" size="smaller" [key]="dropdownTrigger.isDropdownOpen ? 'chevron-up' : 'chevron-down'" />
  </button>
</ng-template>

<eb-dropdown #languageChoiceDropdown>
  <ng-container *ngFor="let language of internationalizationService.availableLanguages">
    <div
      class="eb-dropdown-item"
      [ngClass]="{ 'eb-dropdown-item--active': internationalizationService.activeLanguage === language.id }"
      (click)="setLanguage(language.id)"
    >
      {{ language.id | uppercase }} - {{ language.label }}
    </div>
  </ng-container>
</eb-dropdown>
