export enum DoubleCardsTypes {
    PAY_BY_INSTALMENTS = 'pay-by-instalments',
    PAYPO = 'paypo',
    DEAL_ZONE = 'deal-zone',
}

export enum DoubleCardsWindowTypes {
    DIALOG = 'dialog',
    DRAWER = 'drawer',
}

export type DoubleCards = {
    [key in DoubleCardsTypes]: DoubleCardsContent;
};

export interface DoubleCardsContent {
    title: string;
    subtitle: string;
    description: string;
    link: string;
    linkDescription: string;
}

export const DOUBLE_CARDS_DATA: DoubleCards = {
    [DoubleCardsTypes.PAY_BY_INSTALMENTS]: {
        title: 'shared.delayedPayments.payByInstalments',
        subtitle: 'shared.delayedPayments.conveniently',
        description: 'shared.delayedPaymentsDialog.payByInstalmentsText',
        link: 'https://www.ebilet.pl/lp/platnosci-ratalne-e-raty',
        linkDescription: 'shared.delayedPayments.findOutMore',
    },
    [DoubleCardsTypes.PAYPO]: {
        title: 'shared.delayedPayments.buyNowPayLater',
        subtitle: 'shared.delayedPayments.giveYourselfTimeForFun',
        description: 'shared.delayedPaymentsDialog.payPoText',
        link: 'https://www.ebilet.pl/lp/paypo',
        linkDescription: 'shared.delayedPayments.findOutMore',
    },
    [DoubleCardsTypes.DEAL_ZONE]: {
        title: 'shared.dealZone.dealZoneTitle',
        subtitle: 'shared.dealZone.dealZoneSubTitle',
        description: 'shared.dealZoneDialog.dealZoneDialogContent',
        link: 'https://www.ebilet.pl/lp/strefa-okazji',
        linkDescription: 'shared.dealZoneDialog.dealZoneLinkDescription',
    },
};
