import { RouterModule } from '@angular/router';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiPipeModule } from '@e-bilet/ui-pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TitleCardComponent } from './title-card/title-card.component';
import { UiHeartButtonModule } from '@e-bilet/ui-heart-button';
import { TimerComponent } from './timer/timer.component';
import { JsonLdModule } from '@e-bilet/json-ld';
import { TitleCardSkeletonComponent } from './title-card-skeleton/title-card-skeleton.component';
import { SvgIconComponent } from '@ngneat/svg-icon';

@NgModule({
    imports: [
        CommonModule,
        UiButtonsModule,
        UiPipeModule,
        RouterModule,
        UiHeartButtonModule,
        JsonLdModule,
        TranslocoModule,
        SvgIconComponent,
    ],
    declarations: [TitleCardComponent, TitleCardSkeletonComponent, TimerComponent],
    exports: [TitleCardComponent, TitleCardSkeletonComponent],
})
export class UiTitleCardModule {
    constructor() {}
}
